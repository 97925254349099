import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import {
  Drawer,
  Flex,
  IFieldPayload,
  Select,
  Text,
} from "@wingmate/toolkit";
import { AppContext } from "../../context/AppProvider";
import { useStore } from "../../hooks";
import { ICampaignAttributes } from "../../types/ICampaign";
import { AddLeadSlideoutButton } from "./AddLeadSlideoutButton/AddLeadSlideoutButton";
import { AddLeadForm } from "./AddLeadForm/AddLeadForm";

import "./AddLeadSlideout.scss";

export const AddLeadSlideout = observer(() => {
  const { t } = useTranslation(["default", "common"]);

  const { campaign: defaultCampaign } = useContext(AppContext);

  const rootStore = useStore();
  const { contractStore, leadSlideoutStore } = rootStore;
  const { userCampaigns } = contractStore;
  const { addLeadSlideoutOpen, campaignSelectionIsVisible } = leadSlideoutStore;

  const [campaign, setCampaign] = useState<ICampaignAttributes>(defaultCampaign);

  const closeSlideout = () => {
    setCampaign(defaultCampaign);
    leadSlideoutStore.reset();
  };

  const handleAddLeadButtonClick = () => {
    leadSlideoutStore.setAddLeadSlideoutOpen(true);
  };

  const handleCampaignChanged = (payload: IFieldPayload<string | number>) => {
    const newCampaign = userCampaigns.find((userCampaign) => (
      userCampaign.id === payload.value
    ));

    setCampaign(newCampaign.attributes);
  };

  const renderCampaignOptions = () => userCampaigns.map((userCampaign) => ({
    id: userCampaign.id,
    label: userCampaign.attributes.name,
    value: userCampaign.id,
  }));

  return (
    <>
      <Drawer
        className="AddLeadSlideout"
        open={addLeadSlideoutOpen}
        onClose={closeSlideout}
        getContainer={document.getElementById("pageLayer") as HTMLElement}
        rootStyle={{ position: "absolute" }}
        maskClosable={false}
        title={(
          <Text className="AddLeadSlideout__Title" type="H5">
            {t("common:layouts.slideout.add", { leadLabel: campaign.leadLabel })}
          </Text>
        )}
      >
        {addLeadSlideoutOpen && (
          <Flex align="stretch" gap={16} vertical>
            {campaignSelectionIsVisible && (
              <Select
                id="campaign"
                label={t("common:layouts.slideout.campaign")}
                options={renderCampaignOptions()}
                onChange={handleCampaignChanged}
                defaultValue={campaign.id?.toString()}
                disableClearable
              />
            )}
            <AddLeadForm campaign={campaign} onClose={closeSlideout} />
          </Flex>
        )}
      </Drawer>
      <AddLeadSlideoutButton
        onClick={handleAddLeadButtonClick}
        visible={!addLeadSlideoutOpen}
      />
    </>
  );
});

export default AddLeadSlideout;
